<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Contractor History</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="search-contractor-name" label="Contractor Name:">
                                    <b-input-group size="sm">
                                        <b-input id="search-contractor-name" placeholder="Contractor Name" v-model="nameFilter"/>
                                        <b-input-group-addon>
                                            <b-button @click="nameFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-contractor-type" label="Contractor Type:">
                                    <b-input-group>
                                        <b-select id="search-contractor-type" size="sm" :options="contractorTypeOptions" v-model="typeFilter">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="typeFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-contractor-status" label="Status:">
                                    <b-input-group>
                                        <b-select id="search-contractor-status" size="sm" v-model="statusFilter">
                                            <option :value="null"> - </option>
                                            <option :value="true">Participating</option>
                                            <option :value="false">Not Participating</option>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="statusFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button variant="primary" @click="doSearch"><b-spinner v-if="searching" small type="grow"></b-spinner>Search</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-row>
                        <b-col cols="4">
                            <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage"/>
                        </b-col>
                        <b-col cols="8">
                            <b-form-group :label="'Records Per Page: ' + perPage">
                                <b-input type="range" v-model="perPage" min="10" max="100" step="10"></b-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        Page {{ currentPage }} of {{ pages }}
                    </b-row>
                    <b-row>
                        <b-table small striped hover bordered stacked="md" head-variant="dark"
                                ref="searchResultsTable"
                                selectable
                                select-mode="single"
                                selected-variant="warning"
                                @row-selected="onRowSelected"
                                :sort-compare="customTableColumnSort"
                                :items="results"
                                :fields="tableFields"
                                :per-page="perPage"
                                :current-page="currPage">
                            <template v-slot:cell(type)="row">
                                {{ row.item.type.type }}
                            </template>
                            <template v-slot:cell(state)="row">{{row.item.state | formatState }}</template>
                            <template v-slot:cell(status)="row">
                                <b-badge :variant="row.item.participating ? 'success' : 'warning'">
                                    {{ row.item.participating ? '' : 'Not '}} Currently Participating
                                </b-badge>
                            </template>
                        </b-table>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage"/>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-row>
                        <b-col cols="3">
                            <b-form-group label-size="sm" label="Date Range:">
                                <picker-of-dates v-model="dateRange"
                                                 format="M/d/yyyy"
                                                 clearable range/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4" offset="5">
                            <b-form-group
                                    label-for="display-purposes"
                                    label="Selected Contractor:"
                                    :invalid-feedback="targetContractorSelectedMessage"
                                    :valid-feedback="targetContractorSelectedMessage"
                                    :state="isTargetContractorSelected">
                                <b-form-input
                                    id="display-purposes"
                                    size="lg"
                                    readonly
                                    v-model="selectedContractor"
                                    :state="isTargetContractorSelected"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right">
                            <b-button-group size="sm">
                                <b-button class="mr-2" variant="dark" @click="getPdf">Generate PDF</b-button>
                                <b-button variant="info" @click="clearForm">Clear</b-button>
                            </b-button-group>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import store from '@/store/store';
import _ from 'underscore';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import PickerOfDates from '@/components/shared/PickerOfDates';
import { trimToNull, date } from '@/util/formatters';
import download from '@/util/downloader';
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";


@Component({
    components: {
        PickerOfDates
    },
    filters: {
        // TODO: copy-paste from Contractor.vue
        //  -> will talk to Loren about pulling this out of both places into a common filter
        formatState: (state) => {
            switch (true) {
                case _.isNull(state): return '';
                case _.isString(state): return state; // TODO: possibly do some lookup, to return name or abbreviation
                case _.isObject(state): return state.stateAbbreviation; // TODO: likewise
                default: return state; // TODO: Do some determination as to what the heck this is! :)
            }
        }
    }
})
export default class AuditContractorHistory extends Vue {

    isSearching = false;

    nameFilter = null;
    typeFilter = null;
    statusFilter = null;

    currPage = 0;
    perPage = 10;

    reportDateRange = [null,null];
    reportTargetContractor = {};

    organizations = [];


    get tableFields() {
        return [{
            key: 'name',
            label: 'Name',
            sortable: true
        }, {
            key: 'type',
            label: 'Type',
            sortable: true
        }, {
            key: 'city',
            label: 'City',
            sortable: true
        }, {
            key: 'state',
            label: 'State',
            sortable: true
        }, {
            key: 'status',
            label: 'Status',
            sortable: true
        }];
    }

    customTableColumnSort(row1, row2, key) {
        if (key !== 'status') {
            return false; // this causes b-table will fall back to using the internal sort compare for all other fields
        }
        // currently only need special sort logic for the 'status' column [a.k.a.: 'participating']
        const keyname = 'participating'
        const a = row1[keyname];
        const b = row2[keyname];
        return a === b ? 0 : b ? 1 : -1;
    }

    get searching() {
        return this.isSearching;
    }

    get dateRange() {
        return this.reportDateRange;
    }

    set dateRange(dates) {
        this.reportDateRange = dates;
    }

    get currentPage() {
        if (this.currPage < 1 || this.organizations.length === 0) {
            return 1;
        }
        const numPagesNecessary = Math.ceil(this.organizations.length / this.perPage);
        if (this.currPage > numPagesNecessary) {
            return numPagesNecessary;
        }

        return this.currPage;
    }

    set currentPage(page) {
        this.currPage = page;
    }

    get pages() {
        return Math.ceil(this.organizations.length / this.perPage) || 1;
    }

    get totalRows() {
        return this.organizations.length;
    }

    async doSearch() {
        this.isSearching = true;

        this.organizations = _.chain(this.$store.getters['organizations/getOrganizations'])
            .filter((org) => !!org.type) // Organizations are considered contractors
                                         // when they have a Contractor Type
            .filter((org) => {
                const filter = trimToNull(this.nameFilter);
                return null === filter || new RegExp(filter, 'i').test(org.name);
            })
            .filter((org) => {
                const filter = this.typeFilter;
                return null === filter || filter.id === (org.type || {}).id;
            })
            .filter((org) => {
                const filter = this.statusFilter;
                return null === filter || org.participating === filter;
            })
            .sortBy((org) => org.name)
            .value();

        this.currentPage = 1;
        this.isSearching = false;
    }

    get contractorTypeOptions() {
        const types = this.$store.getters['organizations/getContractorTypes'];
        return _.map(types, (t) => {
            return {
                value: t,
                text: t.type
            };
        });
    }

    get results() {
        return this.organizations;
    }

    onRowSelected(selected) {
        const contractor = selected.pop();
        if (contractor) {
            this.reportTargetContractor = contractor;
        } else {
            this.reportTargetContractor = {};
        }
    }

    get isTargetContractorSelected() {
        return null;
        //return this.reportTargetContractor.id !== undefined;
    }

    get targetContractorSelectedMessage() {
        return this.isTargetContractorSelected ? '' : 'Please select a contractor from the search results table';
    }

    get selectedContractor() {
        const contractor = this.reportTargetContractor;
        if (contractor.name === undefined) {
            return undefined;
        } else {
            return contractor.name;
        }
    }

    clearFilters() {
        this.nameFilter = null;
        this.typeFilter = null;
        this.statusFilter = null;
    }

    clearForm() {
        this.clearFilters();
        this.organizations = [];
        this.reportDateRange = [null,null];
        this.reportTargetContractor = {};
    }

    isReady() {
        if (!this.reportTargetContractor.id) {
            alert("A contractor must be selected");
            return false;
        } else if (!this.reportDateRange[0] || !this.reportDateRange[1]) {
            alert("Please select a date range");
            return false;
        }
        return true;
    }

    async getPdf() {
        if (!this.isReady()) {
            return;
        }
        const reqData = {};
        reqData.contractorId = this.reportTargetContractor.id;
        reqData.fromDate = date(this.reportDateRange[0], date.DEFAULT_DATE_FORMAT) + " 00:00:00";
        reqData.toDate = date(this.reportDateRange[1], date.DEFAULT_DATE_FORMAT) + " 23:59:99";
        try {
            await download('POST', '/api/v1/report/audit-contractor-history', JSON.stringify(reqData));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async beforeRouteEnter(to, from, next) {
        const deps = [
            'organizations/loadOrganizations',
            'organizations/loadContractorTypes'
        ];
        _.each(deps, (action) => {
            store.dispatch(action)
                .catch(error => {
                    const vm = new Vue();
                    vm.$bvToast.toast(error.message, errorToastOptions);
                });
        });
        next();
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Contractor History', null, true));
    }
}
</script>


<style scoped>

</style>
